import { HttpClient } from 'clients/http-client'
import {
  ChangePasswordRequestDto,
  ChangePasswordResponseDto,
  ForgotPasswordRequestDto,
  ForgotPasswordResponseDto,
  LoginRequestDto,
  LoginResponseDto,
  MeResponseDto,
  MfaSetupResponseDto,
  OtpRequestDto,
  OtpResponseDto,
  SetPasswordRequestDto,
  SetPasswordResponseDto
} from 'types/dtos/auth-dtos'

export class AuthService {
  static async login(body: LoginRequestDto) {
    return HttpClient.post<LoginResponseDto>('/auth/login', body)
  }

  static async validateOtp(body: OtpRequestDto, accessToken: string) {
    return HttpClient.post<OtpResponseDto>('/auth/otp', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  }

  static async mfaSetup(accessToken: string) {
    return HttpClient.post<MfaSetupResponseDto>('/me/mfa/setup', undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  }

  static async setPassword(body: SetPasswordRequestDto) {
    return HttpClient.post<SetPasswordResponseDto>('/auth/set-password', body)
  }

  static async forgotPassword(body: ForgotPasswordRequestDto) {
    return HttpClient.post<ForgotPasswordResponseDto>(
      '/auth/forgot-password',
      body
    )
  }

  static async changePassword(
    body: ChangePasswordRequestDto,
    accessToken?: string
  ) {
    return HttpClient.post<ChangePasswordResponseDto>(
      '/me/password/change',
      body,
      accessToken
        ? {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        : undefined
    )
  }

  static async me() {
    return HttpClient.get<MeResponseDto>('/me')
  }

  static async mfaReset() {
    return HttpClient.post('/me/mfa/reset')
  }

  static async logout() {
    return HttpClient.post('/me/logout')
  }
}
