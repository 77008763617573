import { useAccessToken } from 'hooks/use-access-token'
import React, { FunctionComponent, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'

interface AuthGuardProps {
  children: React.ReactNode
}

export const AuthGuard: FunctionComponent<AuthGuardProps> = ({ children }) => {
  const accessToken = useAccessToken()
  const navigate = useNavigate()

  useEffect(() => {
    if (!accessToken) {
      navigate(PATHS.AUTH.SIGN_IN)
    }
  }, [accessToken])

  return <>{accessToken && children}</>
}
