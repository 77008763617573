import React, { PropsWithChildren } from 'react'

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel
} from 'components/ui/form'
import { useConfig } from 'ui-library/contexts'
import { fetchColorReference } from 'utils/editor'
import { cn } from 'utils/shadcn'
import { RowFieldType as Row } from '../../types/row'
import { Label } from '../label'

interface RowFieldProps {
  row: Row
  selectTitle?: () => void
  selectDescription?: () => void
}

export const RowField: React.FunctionComponent<
  PropsWithChildren<RowFieldProps>
> = ({ row, children, selectTitle, selectDescription }) => {
  const { justify, hidden, title, description } = row

  const { brandInfo } = useConfig()

  const backgroundColorRef = row.theme?.backgroundColor

  const backgroundColor =
    backgroundColorRef &&
    fetchColorReference(backgroundColorRef, brandInfo.data.colors)

  if (hidden) {
    return null
  }

  return (
    <FormItem
      className="flex flex-col"
      style={{
        alignItems: justify,
        backgroundColor
      }}
    >
      <FormLabel onClick={selectTitle}>
        <Label label={title} />
      </FormLabel>
      <FormControl>
        <div className={cn(row.field.hidden && 'hidden', 'max-w-60 w-full')}>
          {children}
        </div>
      </FormControl>
      <FormDescription onClick={selectDescription}>
        <Label label={description} />
      </FormDescription>
      {/* TODO: Add custom fomr message */}

      {/* <FormMessage /> */}
    </FormItem>
  )
}
