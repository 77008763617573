import { Button as BaseButton, buttonVariants } from 'components/ui/button'
import React from 'react'

import { css } from '@emotion/css'
import { VariantProps } from 'class-variance-authority'
import { icons } from 'lucide-react'
import { fetchColorReference, fetchReference } from 'utils/editor'
import { conditionalClasses } from 'utils/theme'
import { useConfig } from '../contexts/config-context'
import { ButtonItem } from '../types/button'

interface ButtonProps extends VariantProps<typeof buttonVariants> {
  item: ButtonItem
  isLoading?: boolean
  onClick?: () => void
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  item,
  isLoading = false,
  variant,
  onClick = () => {}
}) => {
  const { brandInfo } = useConfig()

  const { localizedText, theme, icon } = item

  const { style } = theme || {}

  const currentTheme = fetchReference(style, brandInfo.data.buttonStyles)

  const className = theme
    ? conditionalClasses(
        currentTheme?.borderRadius,
        currentTheme?.borderStyle,
        currentTheme?.borderWidth,
        css`
          padding-top: ${currentTheme?.paddingTop}px !important;
          padding-right: ${currentTheme?.paddingRight}px !important;
          padding-bottom: ${currentTheme?.paddingBottom}px !important;
          padding-left: ${currentTheme?.paddingLeft}px !important;

          background-color: ${currentTheme.style === 'textButton'
            ? 'transparent'
            : fetchColorReference(
                currentTheme?.state.default.backgroundColor,
                brandInfo.data.colors
              )};
          color: ${fetchColorReference(
            currentTheme?.state.default.textColor,
            brandInfo.data.colors
          )};
          border-color: ${currentTheme.style === 'textButton'
            ? 'transparent'
            : fetchColorReference(
                currentTheme?.state.default.borderColor,
                brandInfo.data.colors
              )};
          &:hover {
            background-color: ${currentTheme.style === 'textButton'
              ? 'transparent'
              : fetchColorReference(
                  currentTheme?.state.hover.backgroundColor,
                  brandInfo.data.colors
                )};
            color: ${fetchColorReference(
              currentTheme?.state.hover.textColor,
              brandInfo.data.colors
            )};
            border-color: ${currentTheme.style === 'textButton'
              ? 'transparent'
              : fetchColorReference(
                  currentTheme?.state.hover.borderColor,
                  brandInfo.data.colors
                )};
          }
        `
      )
    : ''

  return (
    <BaseButton
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      size={(currentTheme?.size || 'default') as 'default'}
      className={className}
      variant={variant}
      loading={isLoading}
      onClick={onClick}
      leadIcon={icon?.position === 'start' ? icons[icon.name] : undefined}
      tailIcon={icon?.position === 'end' ? icons[icon.name] : undefined}
    >
      {localizedText}
    </BaseButton>
  )
}
