import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from 'components/ui/card'
import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldPassword } from 'components/form/form-field-password'
import { FormProvider } from 'components/form/form-provider'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'
import { AuthService } from 'services/auth-service'
import { toast } from 'sonner'
import * as yup from 'yup'

const ResetPasswordPage: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation()
  const location = useLocation()

  const navigate = useNavigate()

  const queryParams = new URLSearchParams(
    location.search.replace(/&amp;/g, '&')
  )
  const username = queryParams.get('u')
  const token = queryParams.get('t')

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
        t('pages.set-password.password-rules')
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('pages.set-password.password-mismatch')
      )
      .required('Required')
  })

  useEffect(() => {
    if (!username || !token) {
      navigate(PATHS.AUTH.SIGN_IN)
    }
  }, [username, token, navigate])

  const resetPasswordMethods = useForm({
    resolver: yupResolver(validationSchema)
  })
  const resetPasswordSubmit = resetPasswordMethods.handleSubmit((data) => {
    setLoading(true)
    AuthService.setPassword({
      username,
      token,
      password: data.password
    })
      .then((response) => {
        toast.success(t('pages.set-password.toast-success'))
        navigate(PATHS.AUTH.SIGN_IN)
      })
      .catch((error) => {
        //to be implemented in the future
      })
      .finally(() => {
        setLoading(false)
      })
  })

  return (
    <>
      <FormProvider
        methods={resetPasswordMethods}
        onSubmit={resetPasswordSubmit}
      >
        <div className="flex flex-col items-center justify-center h-screen">
          <Card className="w-full max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">
                {t('pages.set-password.title')}
              </CardTitle>
              <CardDescription>
                {t('pages.set-password.description')}
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormFieldPassword
                name="password"
                label={t('pages.set-password.password')}
              />
              <FormFieldPassword
                name="confirmPassword"
                label={t('pages.set-password.confirm-password')}
              />
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit" loading={loading}>
                {t('pages.set-password.button')}
              </Button>
            </CardFooter>
          </Card>
        </div>
      </FormProvider>
    </>
  )
}

export default ResetPasswordPage
