import { GlobalAutocompleteHelper } from 'components/global-autocomplete-helper'
import { useAccessToken } from 'hooks/use-access-token'
import React from 'react'
import { BrandService } from 'services/brand-service'
import { ConfigurationService } from 'services/configuration-service'
import { BrandRequestDTO } from 'types/dtos/brand-dtos'
import { FlattenedJSONSchema, flattenJsonSchema } from 'utils/editor'
import { LabelEditType } from '../types'
import { getSeparators } from '../utils/locale'

type Props = {
  children: React.ReactNode
  defaultLocale: string
  languages: Record<string, string>
}

type State = {
  languages: Record<string, string>
  locale: string
  defaultLocale: string
  separators: {
    group: string
    decimal: string
  }
  setLocale: (locale: string) => void
  formatLabel: (label: LabelEditType, currentLocale?: string) => string
  defaultLabel: (localizedText: Record<string, string>) => string
  brandInfo: BrandRequestDTO | null
  fetchBrandInfo: () => Promise<void>
  externalFields: FlattenedJSONSchema
}

const initialState: State = {} as State

const languageMapping = {
  en_US: 'American English',
  en_GB: 'British English',
  el_GR: 'Greek',
  es_ES: 'Spanish',
  fr_FR: 'French',
  de_DE: 'German',
  it_IT: 'Italian',
  pt_PT: 'Portuguese (Portugal)'
}

export const ConfigContext = React.createContext(initialState)

export const ConfigProvider: React.FunctionComponent<Props> = ({
  children
}) => {
  const accessToken = useAccessToken()

  const [locale, setLocale] = React.useState<string>('en_GB')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [brandInfo, setBrandInfo] = React.useState<any>(null)
  const [languages, setLanguages] = React.useState<Record<string, string>>({})
  const [defaultLocale, setDefaultLocale] = React.useState<string>('en_GB')
  const [externalFields, setExternalFields] =
    React.useState<FlattenedJSONSchema>({})

  const separators = React.useMemo(() => getSeparators(locale), [locale])

  const defaultLabel = React.useCallback(
    (localizedText: Record<string, string>) =>
      localizedText[locale] || localizedText[defaultLocale],
    [locale]
  )

  const formatLabel = React.useCallback(
    (label: LabelEditType) =>
      label.localizedText[locale] || label.localizedText[defaultLocale],
    [locale]
  )

  const isLoggedIn = React.useMemo(() => !!accessToken, [accessToken])

  const fetchInitialConfig = React.useCallback(() => {
    return ConfigurationService.getConfig().then((data) => {
      setLocale(data.defaultLanguage)
      setDefaultLocale(data.defaultLanguage)
      setLanguages(
        data.languages.reduce(
          (acc, lang) => ({ ...acc, [lang]: languageMapping[lang] }),
          {}
        )
      )
      setExternalFields(flattenJsonSchema(data.jinjaVariableSchema))
    })
  }, [])

  const fetchBrandInfo = React.useCallback(async () => {
    return BrandService.getBrand().then((data) => {
      setBrandInfo(data)
    })
  }, [])

  const hasLoadedSuccessfully = React.useMemo(
    () =>
      !!brandInfo &&
      !!externalFields &&
      !!languages &&
      !!locale &&
      !!defaultLocale &&
      !!separators,
    [brandInfo, externalFields, languages, locale, defaultLocale, separators]
  )

  React.useEffect(() => {
    if (isLoggedIn) {
      fetchBrandInfo()
      fetchInitialConfig()
    }
  }, [fetchBrandInfo, fetchInitialConfig, isLoggedIn])

  return (
    <ConfigContext.Provider
      value={{
        externalFields,
        languages,
        locale,
        defaultLocale,
        separators,
        setLocale,
        formatLabel,
        defaultLabel,
        brandInfo,
        fetchBrandInfo
      }}
    >
      {hasLoadedSuccessfully || !isLoggedIn ? children : <div>Loading...</div>}
      <GlobalAutocompleteHelper />
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  const context = React.useContext(ConfigContext)

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}
