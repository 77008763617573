import React from 'react'
import { EyeOffIcon, EyeIcon } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { Box } from 'components/ui/box'
import {
  FormField,
  FormLabel,
  FormItem,
  FormControl,
  FormMessage,
  FormDescription
} from 'components/ui/form'
import { createElement, useState } from 'react'
import { Input } from 'components/ui/input'

type Props = {
  name: string
  label?: string
  placeholder?: string
  description?: string | JSX.Element
}

export const FormFieldPassword: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  description
}) => {
  const { control, getFieldState } = useFormContext()
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Box className="relative">
              <Input
                {...field}
                type={passwordVisibility ? 'text' : 'password'}
                autoComplete="on"
                placeholder={placeholder}
                className={`pr-12 ${getFieldState(name).error && 'text-destructive'}`}
              />
              <Box
                className="absolute inset-y-0 right-0 flex cursor-pointer items-center p-3 text-muted-foreground"
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              >
                {createElement(passwordVisibility ? EyeOffIcon : EyeIcon, {
                  className: 'h-5 w-5'
                })}
              </Box>
            </Box>
          </FormControl>
          <FormMessage />
          {description && <FormDescription>{description}</FormDescription>}
        </FormItem>
      )}
    />
  )
}
