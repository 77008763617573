import { FieldDate, FieldDateEditType } from './date'

export enum FieldType {
  Text = 'text',
  Checkbox = 'checkbox',
  Select = 'select',
  Date = 'date',
  Number = 'number',
  Amount = 'amount'
}

export type FieldText = {
  id: string
  name: string
  maxLength?: number
  minLength?: number
  placeholder?: {
    text: string
  }
  required: boolean
  regex?: string
  regexError?: string
  type: FieldType.Text
  value: string
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldTextEditType = {
  id: string
  maxLength?: number
  minLength?: number
  placeholder?: { localizedText: Record<string, string> }
  required: boolean
  regex?: string
  regexError?: { localizedText: Record<string, string> }
  type: FieldType.Text
  value: string
  readOnly?: boolean
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldCheckbox = {
  id: string
  style: 'checkbox' | 'toggle'
  required: boolean
  hidden?: boolean
  reset: boolean
  checked?: boolean
  checkedValue?: string
  uncheckedValue?: string
  readOnly: boolean
  type: FieldType.Checkbox
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldCheckboxEditType = {
  id: string
  style: 'checkbox' | 'toggle'
  required: boolean
  checked?: boolean
  hidden?: boolean
  reset: boolean
  checkedValue?: string
  uncheckedValue?: string
  readOnly: boolean
  type: FieldType.Checkbox
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldNumber = {
  id: string
  calculated: boolean
  style: 'field' | 'slider'
  maxValue?: number
  minValue?: number
  required: boolean
  round: boolean
  value: string
  type: FieldType.Number
  readOnly: boolean
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldNumberEditType = {
  id: string
  style: 'field' | 'slider'
  maxValue?: number
  minValue?: number
  required: boolean
  round: boolean
  value: string
  type: FieldType.Number
  readOnly: boolean
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldAmount = {
  id: string
  calculated: boolean
  currencyCode: string
  style: 'field' | 'slider'
  maxValue?: number
  minValue?: number
  value: string
  round: boolean
  required: boolean
  type: FieldType.Amount
  readOnly: boolean
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldAmountEditType = {
  id: string
  currencyCode: string
  style: 'field' | 'slider'
  maxValue?: number
  minValue?: number
  round: boolean
  required: boolean
  type: FieldType.Amount
  value: string
  readOnly: boolean
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldSelectEditType = {
  id: string
  values: SelectValueEditType[]
  required: boolean
  type: FieldType.Select
  style: 'dropdown' | 'radioButtons'
  readOnly?: boolean
  maxValues?: number
  initialValue?: string
  value?: string
  placeholder?: Record<string, string>
  theme: {
    style: {
      $ref: string
    }
  }
}

export type SelectValueEditType = {
  id: string
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonLogicCondition?: any
  value: { localizedText: Record<string, string> }
}

export type GeneralFieldEdit = (
  | FieldNumberEditType
  | FieldAmountEditType
  | FieldTextEditType
  | FieldSelectEditType
  | FieldDateEditType
) & {
  id: string
  theme?: {
    style?: {
      $ref: string
    }
  }
}

export type GeneralField =
  | FieldText
  | FieldCheckbox
  | FieldNumber
  | FieldAmount
  | FieldDate
