import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from 'components/ui/card'
import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldText } from 'components/form/form-field-text'
import { FormProvider } from 'components/form/form-provider'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'
import { AuthService } from 'services/auth-service'
import { toast } from 'sonner'
import * as yup from 'yup'

const forgotPasswordValidationSchema = yup.object({
  id: yup.string().required('Required')
})

const ForgotPasswordPage: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const forgotPasswordMethods = useForm({
    resolver: yupResolver(forgotPasswordValidationSchema)
  })

  const forgotPasswordSubmit = forgotPasswordMethods.handleSubmit((data) => {
    setLoading(true)
    AuthService.forgotPassword({ id: data.id! })
      .then((response) => {
        toast.success(t('pages.forgot-password.toast-success'))
        navigate(PATHS.AUTH.SIGN_IN)
      })
      .catch((error) => {
        //to be implemented in the future
      })
      .finally(() => {
        setLoading(false)
      })
  })
  return (
    <>
      <FormProvider
        methods={forgotPasswordMethods}
        onSubmit={forgotPasswordSubmit}
      >
        <div className="flex flex-col items-center justify-center h-screen">
          <Card className="w-full max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">
                {t('pages.forgot-password.title')}
              </CardTitle>
              <CardDescription>
                {t('pages.forgot-password.description')}
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormFieldText
                name="id"
                label={t('pages.forgot-password.username')}
                autoFocus
              />
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit" loading={loading}>
                {t('pages.forgot-password.button')}
              </Button>
            </CardFooter>
          </Card>
        </div>
      </FormProvider>
    </>
  )
}

export default ForgotPasswordPage
