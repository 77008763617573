import { AutosizeTextarea } from 'components/ui/autosize-textarea'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui/form'
import { Input } from 'components/ui/input'
import React, { useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'

interface FormFieldTextProps {
  name: string
  label?: string
  description?: string | JSX.Element
  placeholder?: string
  disabled?: boolean
  autosize?: boolean
  type?: React.HTMLInputTypeAttribute
  autoFocus?: boolean
  className?: string
  suffix?: string
}

export const FormFieldText: React.FunctionComponent<FormFieldTextProps> = ({
  name,
  label,
  description,
  placeholder,
  disabled,
  autosize = false,
  type = 'text',
  autoFocus,
  className,
  suffix
}) => {
  const { control } = useFormContext()

  const spanRef = React.useRef<HTMLSpanElement>(null)
  const [spanWidth, setSpanWidth] = React.useState(0)

  useLayoutEffect(() => {
    if (spanRef.current) {
      setSpanWidth(spanRef.current.offsetWidth)
    }
  }, [suffix])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            {autosize ? (
              <AutosizeTextarea
                {...field}
                autoFocus={autoFocus}
                className={className}
                disabled={disabled}
                placeholder={placeholder}
              />
            ) : (
              <div className="relative">
                <Input
                  {...field}
                  autoFocus={autoFocus}
                  type={type}
                  className={className}
                  disabled={disabled}
                  placeholder={placeholder}
                  style={{ paddingRight: spanWidth + 4 }}
                />
                {suffix && (
                  <span
                    className="absolute h-full flex items-center top-0 right-0 pr-3"
                    ref={spanRef}
                  >
                    {suffix}
                  </span>
                )}
              </div>
            )}
          </FormControl>
          <FormMessage />
          {description && <FormDescription>{description}</FormDescription>}
        </FormItem>
      )}
    />
  )
}
