import { Toaster } from 'components/ui/sonner'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Slide, ToastContainer } from 'react-toastify'

import i18n from 'localization'
import { Router } from 'router'
import * as Yup from 'yup'

import { AuthProvider } from 'contexts/auth-context'
import { CircleCheck, CircleX, Info, TriangleAlert } from 'lucide-react'
import 'react-toastify/dist/ReactToastify.css'
import './css/dotFlashing.css'
import './css/passwordInput.css'
import './css/toastifyStyling.css'

Yup.setLocale({
  mixed: {
    required: 'Required'
  },
  string: {
    min: 'Minimum ${min} characters allowed',
    max: 'Maximum ${max} characters allowed'
  },
  number: {
    min: 'Value must be greater than or equal to ${min}'
  }
})

export const App: React.FunctionComponent = () => (
  <I18nextProvider i18n={i18n}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      closeOnClick={false}
      rtl={false}
      pauseOnHover
      transition={Slide}
      limit={1}
    />
    <AuthProvider>
      <Router />
    </AuthProvider>
    <Toaster
      toastOptions={{
        classNames: {
          title: 'text-sm font-normal',
          success:
            'bg-green-50 text-green-600 space-x-2 border border-green-300 shadow-sm',
          error:
            'bg-red-50 text-red-600 space-x-2 border border-red-300 shadow-sm',
          info: 'bg-purple-50 text-purple-600 space-x-2 border border-purple-300 shadow-sm',
          warning:
            'bg-yellow-50 text-yellow-600 space-x-2 border border-yellow-300 shadow-sm'
        }
      }}
      icons={{
        success: <CircleCheck className="text-green-600" />,
        error: <CircleX className="text-red-600" />,
        info: <Info className="text-purple-600" />,
        warning: <TriangleAlert className="text-yellow-600" />
      }}
    />
  </I18nextProvider>
)
