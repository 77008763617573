import axios, {
  AxiosError,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig
} from 'axios'
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken
} from 'hooks/use-access-token'
import { router } from 'router'
import { PATHS } from 'router/routes'
import { toast } from 'sonner'
import { RefreshTokenResponseDto } from 'types/dtos/auth-dtos'

export const AXIOS_TIMEOUT_DURATION = 30 * 1000

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: AXIOS_TIMEOUT_DURATION,
  headers: {
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = getAccessToken()

    if (token && config.headers.Authorization === undefined) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error: AxiosError) => Promise.reject(error)
)

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (error: AxiosError<any>) => {
    if (
      error.response &&
      error.response.status === HttpStatusCode.Unauthorized &&
      error.config.url !== '/me/logout'
    ) {
      const refreshToken = getRefreshToken()

      try {
        const {
          data: { accessToken }
        } = await axios
          .create({
            baseURL: process.env.REACT_APP_BASE_URL,
            timeout: AXIOS_TIMEOUT_DURATION,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${refreshToken}`
            }
          })
          .post<RefreshTokenResponseDto>('/me/refresh')

        setAccessToken(accessToken)

        const originalConfig = error.config

        originalConfig.headers.Authorization = `Bearer ${accessToken}`

        return await instance(originalConfig) // Ensure the promise is awaited
      } catch (innerError) {
        if (innerError.response.status === HttpStatusCode.Unauthorized) {
          router.navigate(PATHS.AUTH.SIGN_OUT)
        }
      }
    }

    if (error.response?.data) {
      toast.error(JSON.stringify(error.response.data))
    }

    return Promise.reject(error)
  }
)

export class HttpClient {
  static instance = instance

  static async head<T>(url: string, params?: unknown) {
    const response = await this.instance.head<T>(url, { params })

    return response.data
  }

  static async get<T>(url: string, params?: unknown) {
    const response = await this.instance.get<T>(url, { params })

    return response.data
  }

  static async post<T>(url: string, data?: unknown, options?: unknown) {
    const response = await this.instance.post<T>(url, data, options)

    return response.data
  }

  static async put<T>(url: string, data: unknown) {
    const response = await this.instance.put<T>(url, data)

    return response.data
  }

  static async patch<T>(url: string, data: unknown) {
    const response = await this.instance.patch<T>(url, data)

    return response.data
  }

  static async delete<T>(url: string) {
    const response = await this.instance.delete<T>(url)

    return response.data
  }
}
