import { useAccessToken } from 'hooks/use-access-token'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'

export const GuestGuard: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const accessToken = useAccessToken()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (accessToken) {
      navigate(PATHS.DASHBOARD.INDEX)
    }
  }, [accessToken, navigate])

  return <>{children}</>
}
